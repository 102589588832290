import React, { useState, useMemo } from "react";
import Papa from "papaparse";
import { Modal, Form, Input, Select, message, Upload, Progress } from "antd";
import UploadCsv from "../../../components/UploadCsv";
import type { UploadFile } from "antd/es/upload/interface";

const { Option } = Select;

type Props = {
  visible: boolean;
  targetList: any;
  onCancel: any;
  setVisible: Function;
  app: any;
  user: any;
};

type UploadType = {
  label: string;
  value: string;
};

const Add: React.FC<Props> = ({
  visible,
  targetList,
  onCancel,
  setVisible,
  app,
  user,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState<UploadFile<any>[]>([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [progress, setProgress] = useState(0);

  const disabled = useMemo(
    () =>
      !targetList.listSchema.isValidSync(targetList.newList) ||
      !targetList.newList.file,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetList.newList]
  );

  const disableUploadType = useMemo(
    () => !!defaultFileList.length,
    [defaultFileList]
  );

  const beforeUpload = (file: any) => {
    setUploadDisabled(true);
    if (file.type !== "text/csv") {
      message.error(`${file.name} is not a csv file`);
      setUploadDisabled(false);
      return Upload.LIST_IGNORE;
    }

    Papa.parse(file, {
      ...(targetList.newList.type !== "scrub" && { header: true }),
      skipEmptyLines: true,
      delimiter: ",",
      complete: function (results) {
        console.log({ results });
        if (results.errors.length) {
          message.error(
            `File has some errors: ${JSON.stringify(results.errors)}`
          );
          setDefaultFileList([]);
          setUploadDisabled(false);
          return;
        }

        // validates file is not empty
        if (!results.data.length) {
          message.error("File must not be empty");
          setDefaultFileList([]);
          setUploadDisabled(false);
          return;
        }

        if (
          targetList.newList.type !== "scrub" &&
          !results.meta.fields?.includes("email")
        ) {
          message.error("CSV must have an email column");
          setDefaultFileList([]);
          setUploadDisabled(false);
          return;
        }

        if (
          targetList.newList.type === "email_only" &&
          results.meta.fields?.length !== 1
        ) {
          message.error("File should only have <email> column");
          setDefaultFileList([]);
          setUploadDisabled(false);
          return;
        }

        if (
          targetList.newList.type === "suppression" &&
          results.meta.fields?.length !== 1
        ) {
          message.error("File should only have <email> column");
          setDefaultFileList([]);
          setUploadDisabled(false);
          return;
        }

        if (targetList.newList.type === "full_profile") {
          if (results?.meta?.fields && results?.meta?.fields?.length < 2) {
            message.error("File should have more than one columns");
            setDefaultFileList([]);
            setUploadDisabled(false);
            return;
          }
        }

        if (targetList.newList.type === "hcpid") {
          if (!results.meta.fields?.includes("hcpid")) {
            message.error("File should have <hcpid> column");
            setDefaultFileList([]);
            setUploadDisabled(false);
            return;
          }

          if (
            results.data.some(
              (line: any) => line.email.split("@")[1] !== "pslqc.com"
            )
          ) {
            message.error("All emails should has <pslqc.com> domain");
            setDefaultFileList([]);
            setUploadDisabled(false);
            return;
          }
        }

        if (
          targetList.newList.type === "scrub" &&
          results.data.some(
            (line: any) =>
              line.length > 2 || !Number(line[0]) || !Number(line[1])
          )
        ) {
          message.error(
            "File should have only 2 columns: Both columns must be numeric."
          );
          setDefaultFileList([]);
          setUploadDisabled(false);
          return;
        }

        targetList.actions.setNewList({
          ...targetList.newList,
          records: results.data.length,
          segment_name: file.name.split(".csv")[0],
          filename: file.name.split(".csv")[0].split(" ").join("_"),
          file: file,
          csv: Papa.unparse(results.data, { newline: "\n" }).replace(/"/g, ""),
        });

        form.setFieldsValue({
          segment_name: file.name.split(".csv")[0],
        });
        setUploadDisabled(false);
      },
    });

    return false;
  };

  const onChange = (info: any) => {
    setDefaultFileList(info.fileList);
    if (!info.fileList.length) {
      targetList.actions.setNewList({
        ...targetList.newList,
        segment_name: "",
        filename: "",
        file: null,
      });
      form.setFieldsValue({ segment_name: "" });
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Modal
      title="New Client Target List"
      open={visible}
      onOk={() => {
        if (targetList.newList.type === "suppression") {
          targetList.actions.addNewSuppressionList({
            setVisible,
            setIsLoading,
            form,
            setProgress,
          });
          return;
        }
        targetList.actions.addNewList(setVisible, setIsLoading, form);
      }}
      okText="Submit"
      okButtonProps={{
        loading: isLoading,
        disabled,
      }}
      onCancel={onCancel}
      width={550}
    >
      <Form name="basic" layout="vertical" form={form}>
        <Form.Item label="Target List Type" style={{ width: 250 }}>
          <Select
            placeholder="Select Type"
            onChange={(value) => targetList.actions.onTypeChange(value)}
            value={targetList.newList.type}
            disabled={disableUploadType}
          >
            {targetList.uploadTypes
              .filter((type: UploadType) => {
                if (
                  Array.isArray(user.userGroups) &&
                  user.userGroups.includes("RING:Ami") &&
                  !user.userGroups.includes("RING:Mec")
                ) {
                  return type.value === "email_only";
                }

                return true;
              })
              .map((type: UploadType, i: number) => (
                <Option key={i} value={type.value}>
                  {type.label}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="File">
          {/* <Upload hcpid={hcpid} form={form} /> */}
          <UploadCsv
            beforeUpload={beforeUpload}
            onChange={onChange}
            defaultFileList={defaultFileList}
            setDefaultFileList={setDefaultFileList}
            disabled={uploadDisabled}
            setDisabled={setUploadDisabled}
            itemRender={(originNode) => {
              return <div style={{ width: 500 }}>{originNode}</div>;
            }}
          />
        </Form.Item>

        {targetList.newList.type !== "suppression" && (
          <Form.Item
            label={`Brands ${
              targetList.newList.type === "scrub" ? "(Target List)" : ""
            }`}
            style={{ width: 250 }}
          >
            <Select
              showSearch
              mode="multiple"
              placeholder="Select brands"
              optionFilterProp="children"
              onChange={(value) =>
                targetList.actions.updateNewList("brands", value)
              }
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={targetList.newList.brands}
            >
              {targetList.newList.type === "scrub"
                ? app.config?.ALL_BRANDS.map((brand: any, i: number) => (
                    <Option key={i} value={brand.brandid}>
                      {brand.branddescription}
                    </Option>
                  ))
                : app.config?.BRANDS.map((brand: any, i: number) => (
                    <Option key={i} value={brand.id}>
                      {brand.name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={
            ["scrub", "suppression"].includes(targetList.newList.type)
              ? "List Name"
              : "Segment Name"
          }
          name="segment_name"
          style={{ width: 250 }}
          extra="Underscores not allowed. 64 character max."
        >
          <Input
            placeholder="Type name here"
            onChange={(e) => {
              targetList.actions.setNewList({
                ...targetList.newList,
                segment_name: e.target.value,
              });
            }}
            value={targetList.newList.segment_name}
          />
        </Form.Item>
      </Form>
      {targetList.newList.type === "suppression" && progress > 0 ? (
        <Progress percent={progress} />
      ) : null}
    </Modal>
  );
};

export default Add;
