import React, { useState, useMemo, useEffect } from "react";
import Papa from "papaparse";
import { Form, Input, Space, Button, message, Upload, Alert } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import UploadCsv from "../../../../components/UploadCsv";
import type { UploadFile } from "antd/es/upload/interface";
import useLdl from "../../../../hooks/useLdl";
import useAuth from "../../../../hooks/useAuth";
import CopyToClipBoard from "../../../../components/CopyToClipBoard";

type Props = {};

const GlobalUnsubscribe: React.FC<Props> = () => {
  const ldl = useLdl();
  const { user }: any = useAuth();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState<UploadFile<any>[]>([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorList, setErrorList] = useState("");
  const disabled = useMemo(
    () =>
      !ldl.globalUnsubscribeSchema.isValidSync(ldl.globalUnsubscribe) ||
      !ldl.globalUnsubscribe.file,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ldl.globalUnsubscribe]
  );

  useEffect(() => {
    if (errorList) message.error(errorList);
  }, [errorList]);

  const validateColumns = (results: any): boolean =>
    results.data.some((line: any, index: number) => {
      const notANumber = line[0] && !Number(line[0]);
      const invalidStatus = ![
        "CUST_UNSUBSCRIBE",
        "BOUNCE_UNSUBSCRIBE",
        "GLOBAL_UNSUBSCRIBE",
        "CUST_CARE_SUSPEND",
      ].includes(line[1]);
      // if value is not a number
      if (notANumber) {
        setErrorList(`Line ${index + 1}: [${line[0]}] is not a valid number`);
      }
      if (invalidStatus) {
        setErrorList(`Line ${index + 1}: [${line[1]}] is not a valid status`);
      }
      return notANumber || invalidStatus;
    });

  const beforeUpload = (file: any) => {
    setUploadDisabled(true);
    setErrorList("");
    if (file.type !== "text/csv") {
      message.error(`${file.name} is not a csv file`);
      setUploadDisabled(false);
      return Upload.LIST_IGNORE;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      delimiter: ",",
      complete: function (results) {
        console.log({ results });
        // validates file is not empty
        if (!results.data.length) {
          message.error("File must not be empty");
          setDefaultFileList([]);
        }

        // validates file only has 2 columns
        if (results.data.some((line: any) => line.length === 0)) {
          message.error("File should have at least one column");
          setDefaultFileList([]);
        }

        if (validateColumns(results)) {
          setUploadDisabled(false);
          setDefaultFileList([]);
          return;
        }

        ldl.actions.setGlobalUnsubscribe({
          ...ldl.globalUnsubscribe,
          filename: file.name.split(".csv")[0].split(" ").join("_"),
          file: file,
          csv: Papa.unparse(results.data, { newline: "\n" }).replace(/"/g, ""),
        });

        setUploadDisabled(false);
      },
    });

    return false;
  };

  const onChange = (info: any) => {
    setDefaultFileList(info.fileList);
    if (!info.fileList.length) {
      ldl.actions.setGlobalUnsubscribe({
        ...ldl.globalUnsubscribe,
        filename: "",
        file: null,
        csv: "",
      });
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader ghost={false} title="LDL" subTitle="Global Unsubscribe" />
        {successMessage && (
          <Space
            size={"large"}
            direction="horizontal"
            style={{ marginBottom: 20 }}
          >
            <Alert
              message={`The job ${successMessage} has been created.`}
              type="info"
            />
            <CopyToClipBoard text={successMessage} />
          </Space>
        )}
      </div>
      <Space direction="vertical" size={"large"}>
        <Form name="basic" layout="vertical" form={form}>
          <Space direction="horizontal" size={"large"}>
            <Form.Item
              label="User Name"
              name="username"
              style={{ width: 250 }}
              initialValue={user?.usernameOG}
            >
              <Input disabled readOnly />
            </Form.Item>

            <Form.Item
              label="Request Date"
              name="requestDate"
              style={{ width: 250 }}
              initialValue={new Date().toISOString()}
            >
              <Input disabled readOnly />
            </Form.Item>
          </Space>

          <Form.Item label="File To Load">
            <UploadCsv
              beforeUpload={beforeUpload}
              onChange={onChange}
              defaultFileList={defaultFileList}
              setDefaultFileList={setDefaultFileList}
              disabled={uploadDisabled}
              setDisabled={setUploadDisabled}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="button-primary"
              type="primary"
              onClick={() =>
                ldl.actions.uploadGlobalUnsubscribe({
                  setIsLoading,
                  form,
                  setDefaultFileList,
                  setDisabled: setUploadDisabled,
                  setSuccessMessage,
                })
              }
              disabled={disabled}
              loading={isLoading}
              style={{ width: 250, marginTop: 30 }}
            >
              EXECUTE
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
};

export default GlobalUnsubscribe;
