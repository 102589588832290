import { Button, Space, Popconfirm, Tooltip } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { format, compareAsc } from "date-fns";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

interface DataType {
  pk: string;
  name: string;
  created: number;
  created_by: string;
  last_triggered: number;
  trigger_count: number;
  updated: number;
  updated_by: string;
  expires: number;
}

export const columns = (
  automations: any,
  setShowEditModal: Function,
  setShowCsvModal: Function
): ColumnsType<DataType> => [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) =>
      typeof name === "string" && name.length > 50
        ? `${name.slice(0, 50)}...`
        : name,
  },
  {
    title: "Created",
    dataIndex: "created",
    defaultSortOrder: "descend",
    align: "center",
    sorter: (a, b) => compareAsc(new Date(a.created), new Date(b.created)),
    render: (created, row) => {
      const canonicalUpdatedBy = row.updated_by
        ? row.updated_by
        : row.created_by;
      const tooltipTitle = () => {
        return (
          <>
            Updated on {format(new Date(row.updated), "dd MMM yyyy H:mm:ss")}
            <br />
            Updated by {canonicalUpdatedBy}
          </>
        );
      };
      return (
        <span>
          <Tooltip placement="leftTop" title={tooltipTitle}>
            {format(new Date(created), "dd MMM yyyy H:mm:ss")}
          </Tooltip>
        </span>
      );
    },
  },
  {
    title: "Trigger Count",
    dataIndex: "trigger_count",
    align: "center",
  },
  {
    title: "Last Triggered",
    dataIndex: "last_triggered",
    align: "center",
    render: (last_triggered) =>
      last_triggered
        ? format(new Date(last_triggered), "dd MMM yyyy H:mm:ss")
        : "Never",
  },
  {
    title: "Expires on",
    dataIndex: "expires",
    align: "center",
    render: (expires) =>
      expires ? format(new Date(expires), "dd MMM yyyy H:mm:ss") : "Never",
  },
  {
    title: "Action",
    align: "center",
    dataIndex: "pk",
    render: (id, record: any) => {
      return (
        <Space size="large">
          <Popconfirm
            title={`Are you sure you want to ${
              record.enabled ? "Disable" : "Enable"
            } automation ${id} ?`}
            placement="top"
            onConfirm={() => {
              automations.actions.changeStatus({
                ...record,
                enabled: !record.enabled,
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            okButtonProps={{ className: "button-warning" }}
            cancelText="No"
          >
            <Button
              className={record.enabled ? "button-primary" : ""}
              // type="primary"
              // className={record.enabled ? "button-warning" : "button-success"}
              loading={!!automations.loadingStatus[id]}
              icon={record.enabled ? <CloseOutlined /> : <CheckOutlined />}
            />
          </Popconfirm>
          <Tooltip
            title="Download triggered events as CSV"
            mouseEnterDelay={0.5}
          >
            <Button
              className="button-primary"
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => {
                setShowCsvModal(false);
                automations.actions.downloadAutomation(
                  id,
                  record,
                  setShowCsvModal
                );
              }}
            />
          </Tooltip>
          <Button
            className="button-primary"
            type="primary"
            onClick={() => {
              setShowEditModal(true);
              automations.actions.getAutomation(id);
            }}
            icon={<EditOutlined />}
          />
          <Popconfirm
            title={`Are you sure you want to delete automation ${id} ?`}
            placement="top"
            onConfirm={() => automations.actions.deleteAutomation(id)}
            onCancel={() => {}}
            okText="Yes"
            okButtonProps={{ danger: true }}
            cancelText="No"
          >
            <Button
              className="button-primary"
              type="primary"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      );
    },
  },
];
