import { Tooltip, Badge } from "antd";
import type { ColumnsType } from "antd/lib/table";
import compareAsc from "date-fns/compareAsc";

interface DataType {
  brandId: string;
  brandDesc: string;
  campaignID: string;
  campaignDesc: string;
  action: string;
  status: string;
  count: number;
}

const getStatus = (status: string) => {
  switch (status) {
    case "Cancelled":
      return "error";
    case "Completed with warnings":
      return "warning";
    case "Completed":
      return "success";
    case "Scheduled":
      return "processing";
    default:
      return "default";
  }
};

export const columns = (): ColumnsType<DataType> => [
  {
    title: "Brand ID",
    dataIndex: "brandId",
    align: "left",
    sorter: (a, b) => a.brandId.localeCompare(b.brandId),
  },
  {
    title: "Brand Desc",
    dataIndex: "brandDesc",
    align: "left",
    sorter: (a, b) => a.brandDesc.localeCompare(b.brandDesc),
  },
  {
    title: "Campaign ID",
    dataIndex: "campaignID",
    align: "right",
    sorter: (a, b) => a.campaignID.localeCompare(b.campaignID),
  },
  {
    title: "Campaign Desc",
    dataIndex: "campaignDesc",
    align: "left",
    sorter: (a, b) => a.campaignDesc.localeCompare(b.campaignDesc),
  },
  {
    title: "Action",
    dataIndex: "action",
    align: "left",
    sorter: (a, b) => a.action.localeCompare(b.action),
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "left",
    sorter: (a, b) => a.status.localeCompare(b.status),
  },
  {
    title: "Count",
    dataIndex: "count",
    align: "left",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.count - b.count,
  },
];

export const jobsColumns = (): ColumnsType<DataType> => [
  {
    title: "Submitted Date",
    dataIndex: "submitteddate",
    align: "left",
    defaultSortOrder: "descend",
    sorter: (a: any, b: any) =>
      compareAsc(new Date(a.submitteddate), new Date(b.submitteddate)),
  },
  {
    title: "Scheduled Date",
    dataIndex: "scheduleddate",
    align: "left",
  },
  {
    title: "Submitted By",
    dataIndex: "submittedby",
  },
  {
    title: "Job Status",
    dataIndex: "jobstatus",
    align: "left",
    sorter: (a: any, b: any) => a.jobstatus.localeCompare(b.jobstatus),
    render: (jobstatus) => (
      <Badge text={jobstatus} status={getStatus(jobstatus)} />
    ),
  },
  {
    title: "File Name",
    dataIndex: "filename",
    align: "left",
    ellipsis: {
      showTitle: false,
    },
    render: (filename) => (
      <Tooltip placement="leftTop" title={filename}>
        {filename}
      </Tooltip>
    ),
  },
  {
    title: "Job Name",
    dataIndex: "jobname",
    align: "left",
    ellipsis: {
      showTitle: false,
    },
    render: (jobname) => (
      <Tooltip placement="leftTop" title={jobname}>
        {jobname}
      </Tooltip>
    ),
  },
  {
    title: "Source",
    dataIndex: "source",
    align: "left",
    defaultSortOrder: "descend",
    ellipsis: {
      showTitle: false,
    },
    render: (source) => (
      <Tooltip placement="leftTop" title={source}>
        {source}
      </Tooltip>
    ),
  },
  {
    title: "Source Process",
    dataIndex: "sourceprocess",
    align: "left",
    ellipsis: {
      showTitle: false,
    },
    render: (sourceprocess) => (
      <Tooltip placement="leftTop" title={sourceprocess}>
        {sourceprocess}
      </Tooltip>
    ),
  },
];
