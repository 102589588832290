import { useState, useEffect } from "react";
import { Table, Button, Spin, Input, Space } from "antd";
import useTargetList from "../../../hooks/useTargetList";
import useApp from "../../../hooks/useApp";
import useAuth from "../../../hooks/useAuth";
import { columns } from "./config";
import {
  PlusOutlined,
  SearchOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import AddNewList from "./Add";

function Home() {
  const targetList = useTargetList();
  const app = useApp();
  const { user } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  useEffect(() => setFilteredData(targetList.data), [targetList.data]);

  const onCancel = () => setShowUploadModal(false);

  const onTableChange = (pagination: any, tablefilters: any, sorter: any) => {
    if (searchText) return;
    const { current, pageSize } = pagination;
    const query: any = {
      query: {
        limit: pageSize,
        page: current,
        ...(sorter &&
          Object.keys(sorter) && {
            sort: sorter?.order === "ascend" ? true : false,
          }),
      },
    };

    targetList.actions.getList(query);
  };

  if (!targetList.listReady || !app.appReady)
    return (
      <div className="automations-container">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="automations-container">
      {showUploadModal && (
        <AddNewList
          visible={showUploadModal}
          onCancel={onCancel}
          targetList={targetList}
          setVisible={setShowUploadModal}
          app={app}
          user={user}
        />
      )}
      <div className="add-automation-container">
        <Button
          className="button-primary"
          type="primary"
          onClick={() => setShowUploadModal(true)}
          icon={<PlusOutlined />}
        >
          Upload New List
        </Button>
      </div>

      <Space direction="horizontal" size={"large"}>
        <Input
          placeholder="Filter Target Lists"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          className="button-primary"
          type="primary"
          onClick={() => {
            targetList.actions.getList({
              query: {
                searchQuery: searchText,
              },
            });
          }}
          icon={<SearchOutlined />}
        />
        <Button
          className="button-primary"
          type="primary"
          onClick={() => {
            setSearchText("");
            targetList.actions.getList({ query: {} });
          }}
          icon={<ReloadOutlined />}
        />
      </Space>
      <Table
        title={() => <h2>Target Lists</h2>}
        columns={columns(app)}
        pagination={{ ...targetList.pagination, showSizeChanger: false }}
        dataSource={filteredData}
        rowKey={(record) => record.pk}
        onChange={onTableChange}
        loading={targetList.isLoading}
      />
    </div>
  );
}

export default Home;
