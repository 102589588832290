import React, { useState, useMemo } from "react";
import { Form, Input, Space, Button, Checkbox, Select, Table } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import _ from "lodash";
import useLdl from "../../../../hooks/useLdl";
import useAuth from "../../../../hooks/useAuth";
import useApp from "../../../../hooks/useApp";
import { jobsColumns } from "./config";
const { Option } = Select;
type Props = {};

type LogGroup = {
  name: string;
  value: string;
};

const LoadLeasedEmails: React.FC<Props> = () => {
  const ldl = useLdl();
  const { user }: any = useAuth();
  const app: any = useApp();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const disabled = useMemo(
    () => !ldl.logViewerSchema.isValidSync(ldl.logViewer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ldl.logViewer]
  );

  return (
    <>
      <div className="site-page-header-ghost-wrapper">
        <PageHeader ghost={false} title="LDL" subTitle="Log Viewer" />
      </div>
      <Space direction="vertical" size={"large"}>
        <Form name="basic" layout="vertical" form={form}>
          <Space direction="horizontal" size={"large"}>
            <Form.Item
              label="User Name"
              name="username"
              style={{ width: 250 }}
              initialValue={user?.usernameOG}
            >
              <Input disabled readOnly />
            </Form.Item>

            <Form.Item
              label="Request Date"
              name="requestDate"
              style={{ width: 250 }}
              initialValue={new Date().toISOString()}
            >
              <Input disabled readOnly />
            </Form.Item>
          </Space>

          <div>
            <Space direction="horizontal" size={"large"}>
              <Form.Item label="Log Group" style={{ width: 250 }}>
                <Select
                  showSearch
                  placeholder="Select Log Group"
                  onChange={(value) =>
                    ldl.actions.setLogViewer({
                      ...ldl.logViewer,
                      logGroup: value,
                    })
                  }
                  value={ldl.logViewer.logGroup}
                >
                  {app.config?.LOG_GROUPS?.map(
                    (logGroup: LogGroup, i: number) => (
                      <Option key={i} value={logGroup.value}>
                        {logGroup.name}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>

              <Form.Item label="Job Name" name="jobname" style={{ width: 250 }}>
                <Input
                  placeholder="Job Name"
                  onChange={(e) =>
                    ldl.actions.setLogViewer({
                      ...ldl.logViewer,
                      jobName: e.target.value,
                    })
                  }
                  value={ldl.logViewer.jobName}
                />
              </Form.Item>
            </Space>
          </div>

          <div>
            <Checkbox.Group
              options={app.config?.LOG_TYPES}
              onChange={(checkedValues: any) =>
                ldl.actions.setLogViewer({
                  ...ldl.logViewer,
                  logTypes: checkedValues,
                })
              }
              defaultValue={ldl.logViewer.logTypes}
            />
          </div>

          <Form.Item>
            <Button
              className="button-primary"
              type="primary"
              onClick={() =>
                ldl.actions.getLogs({
                  setIsLoading,
                  form,
                })
              }
              disabled={disabled}
              loading={isLoading}
              style={{ width: 250, marginTop: 30 }}
            >
              EXECUTE
            </Button>
          </Form.Item>
        </Form>

        {!_.isEmpty(ldl.ldlLogs) && (
          <Table
            style={{ width: window.screen.width * 0.9 }}
            columns={jobsColumns()}
            pagination={{ pageSize: 50 }}
            dataSource={ldl.ldlLogs.map((item: any, i) => ({
              key: i,
              ...item,
            }))}
            rowKey={(record: any) => record.key}
            scroll={{ x: 2000 }}
            bordered
            size="small"
            loading={isLoading}
          />
        )}
      </Space>
    </>
  );
};

export default LoadLeasedEmails;
